.survey-detail{
    min-height: 500px;
    max-width: 500px;
    margin: auto;
    margin-top: 60px;
}
.contCreacion{
    margin-top: 11px;
    border-left: 1px solid #ccc;
    padding-left: 15px;
}

.survey-detail h2{
    color: #232c4a;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
}

.survey-detail .primary-card-section h4{
    color: #404040 !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-transform: uppercase;
}

.survey-detail .primary-card-section p{
    color: #404040 !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.survey-detail .ant-row{
    padding: 20px 20px;
}

.answered-surveys{
    border: 1px solid #10b48c;
    
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100px;
    width: 100px;
    border-radius: 50%;

    
    color: #10b48c;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;

}
.selectedSurvery{
    border-bottom: 4px solid #8fafb4;
}
.pasos{
    position: absolute;
    top: 19px;
}
.ant-modal{
    padding-bottom: 0px !important;
}
.agregarPregunta{
    width: 18px;
    margin-right: 10px;
}
.eliminarPregunta{
    width: 18px;
    margin-right: 10px;
    cursor: pointer;
    position: absolute;
    right: 22px;
}
.contPreguntaAgregar{
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
}
.contPreguntaAgregar span{
    font-size: 12px !important;
}
.itemPregunta{
    margin-top: 25px;
}
.contOpcion{
    text-align: right;
    margin-top: 10px;
}
.contOpcion input{
    width: 60% !important;
    margin-right: 10px;
}
.contOpcion img{
   cursor: pointer;
}
.bloqueEncuesta{
    border-left: 1px solid #C5C5C5;
    padding-left: 20px;
}
.preguntaHeader{
    color: #10b48c;
    text-transform: uppercase;
    font-family: Poppins;
    font-size: 14px;
}
.preguntaItem{
    color: #232C4A;
    font-family: Poppins;
    font-size: 17px;
}
.respuesta{
    color: #000;
    text-transform: uppercase;
    font-family: Poppins;
    padding-left: 50px;
    font-size: 13px;
    margin-right: 15px;
    min-width: 150px;
    max-width: 300px;
}
.respuestaCommentary{
    color: #000;
    text-transform: uppercase;
    font-family: Poppins;
    padding: 5px 15px 5px 15px;
    font-size: 13px;
    margin-right: 15px;
    width: 100%;
    border: 1px solid #D2D2D2;
}
.grafica{
    height: 25px;
    width: 150px;   
}
.graficaVerde{
    background-color: #10b48c;
    height: 25px;
}
.itemRespuesta{
    padding: 7px 0px;
    display: flex;
}
.cantidad{
    margin-left: 20px;
    color: #000;
    font-size: 13px;
    text-transform: uppercase;
    font-family: Poppins;
}
.cantidad span{
    color:#10b48c;
    font-size: 13px;
}

.contPregunta{
    border-bottom: 1px solid #C9C9C9;
    padding: 20px 0px;
}
.scroll{
    overflow-x: hidden;
    overflow-y: auto;
}
.sheets{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.descargar{
    border: 1px solid #10B48C;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
}
.ant-modal-close{
    width: 100px;
}

.react-tabs__tab {
    color: #8C92A5;
    font-family: Poppins;
    font-size: 13px;
    width: 50%;
    text-align: center;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #808080 !important;
    margin-bottom: 10px;
}
.react-tabs__tab--selected {
    background-color: transparent !important;
    background: transparent !important;
    border-color: transparent !important;
    color: #10B48C !important;
    border-bottom: 3px solid #10B48C !important;
    font-family: Poppins;
    font-size: 13px;
}

.react-tabs__tab:focus {
    background: transparent !important;
}

.react-tabs__tab:focus:after {
    background: transparent !important;
}
.ant-modal-close {
    width: 80px;
}
.ant-modal-close-x {
    text-align: right !important;
}
.centerSpinner{
    text-align: center;
    display: flex;
    justify-content: center;
}
.modalEncuesta .ant-modal-close-x {
    text-align: center !important;
}
.iconOpcionPago{
    width: 80px;
    display: block;
    margin: 0px auto;
    margin-bottom: 5px;
}
.opcionPago{
    flex-direction: column;
    text-align: center;
    background-color: #F2F2F2;
    margin: 0px 20px;
    padding: 15px 10px;
}
.leyenda{
    font-size: 10px;
    text-align: justify;
}
.contOpciones{
    margin-top: 15px;
}
.widthFooter{
    width: 700px;
    margin: 0px auto;
}
.inputValor{
    width: 100%;
    text-align: center;
    border-radius: 10px !important;
    border: 1px solid #dedede;
    padding: 5px 10px;
}
.inputValorRead{
    width: 100%;
    text-align: center;
    border-radius: 10px !important;
    padding: 5px 10px;
}
.iconMetodo{
    width: 40px;
    margin-right: 25px;
}
.shape{
    width: 200px;
}
.iconFactura{
    width: 70px;
    margin-top: 15px;
    position: absolute;
}
.contVacio{
    justify-content: center;
    display: flex;
    width: 200px;
    margin: 0px auto;
    margin-bottom: 10px;
}
.contCenter{
    justify-content: center;
    text-align: center;
    margin-top: 150px;
}
.montoDetalle{
    color: #10b48c;
    display: block;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}
.tituloDetalle{
    font-size: 12px;
}
.fechaDetalle{
    color: #404040;
    display: block;
    font-family: Poppins;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 15px;
}
.iconButton{
    width: 22px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #10b48c !important;
}

 .ant-tabs-ink-bar {
    position: absolute;
    background: #10b48c !important;
    pointer-events: none;
 }

 .and-tabs-nav-list {
   
   border-bottom: solid;
   border-bottom-color: #000;
 }