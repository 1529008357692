.wrapper{
    padding: 15px 30px;
}

.header-date{
    background-color: #232c4a;
}

.header-date .container-date{
    position: relative;
}

.header-date .container-date .title{
  color: #07c180;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}

.header-date .container-date .date{
  color: #c1d5d8;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  right: 0px;
  top: 8px;
}

.header-alert{
    
  background: #fc4850;
  background-blend-mode: normal;
}

.header-alert .alert-side{
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin: auto;
  text-align: center;
  top: 4px;
  position: relative;
}

.side-alert-wrapper{
  background-color: #d6e3e5;
  position: relative;
  height:100vh;
  display: flex;
}


.side-alert-wrapper .side-alert-container{
  position: absolute;
  height:100vh;
  width: 100%;
}
.side-alert-wrapper 
.side-alert-container
.alert-list{

}
.side-alert-wrapper 
.side-alert-container
.event-list{
  width: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.side-alert-wrapper 
.side-alert-container
.event-list .header-title{
  padding: 20px 0px;
  margin: auto;
  text-align: center;
  background: #07c180;
  background-blend-mode: normal;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.alert-modal-header{
  padding-left: 10px;
}


.alert-modal-header h2{
  color: #232C4A;
  text-transform: capitalize;
  font-size: 20px;
}
.contSubir{
  padding: 15px 0px;
  text-align: right;
  display: none;
}
