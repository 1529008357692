.boxcontent-container {
  margin-left: "2rem";
  margin-right: "2rem";
  padding-left: "2rem"

}

.boxcontent-container .card-title {
  color: #777777;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
}

.boxcontent-container .card-description{
  color: #232c4a !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.banner {
 

}
.banner .title {
  color: #232c4a;
  font-size: 22px;
  font-weight: 500;
}

.banner .paragraph {
  color: #232c4a;
  font-size: 14px;
  font-weight: 500;
}

.despliegue {

}

.despliegue .title {
  color: #3c5d8f;
font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 20px;
}

.despliegue .description {
  color: #505050;
font-family: Poppins;
font-size: 16px;
font-weight: 400;
}

.despliegue .subtitle {
  font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 16px;
}

.despliegue .result {
  color: #505050;
font-family: Poppins;
font-size: 14px;
font-weight: 500;

}

.create-news {
  color: #585858;
font-family: Poppins !important;
font-size: 14px;
font-weight: 500;
}


