@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html, body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    position: relative;
}

a, p, span, h1, h2, h3, h4, tr, th, td{
    font-family: Poppins;
}

.wrapper-container{
    display: flex;
    background: #eaeff2;
}

.ant-layout-sider{
    background-color: #232C4A !important;
}

.sidebar-wrapper{
    overflow: hidden !important;
}

.sidebar{
    background-color: #232C4A;
    height: 100%;
    padding: 0px !important;
    width: 90px;
    overflow-y: scroll !important;
    position: relative;
    top: 0;
    bottom: 0;
}
.sidebar::-webkit-scrollbar {
    display:none;
}

.sidebar .logo{
    display: block;
    margin: auto;
    padding-top: 27px;
    margin-bottom: 30px;
    width: 60px;
}

.sidebar .item{
    text-align: center;
}

.sidebar ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
}


.sidebar ul img{
    margin-bottom: 5px;
    width: 50px;
}

.active{
    background-color: #10b48c;
    padding: 0px 0px;
}

.active-blue{
    background-color: #232c4a;
    padding: 25px 0px;
}

.sidebar .item a:hover{
    background-color: #10b48c;
    
}
.ant-layout-content{
    min-height: 100vh !important;
}


.sidebar .item a{
    display: block;
    color: #ddfff7;
    font-family: Poppins;
    font-size: 11px;
    padding: 25px 0px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}

.sidebar .item span{
    display: block;
}

.submenu{
    display: block;
    position: absolute;
    background: #232c4a;
    height: 100%;
    z-index: 100;
    left: 80px;
    min-width: 250px;
}

.submenu .submenu-container .submenu-header{
    background-color: #1F2741;
    padding: 0px 20px;
}
.submenu .submenu-container .submenu-header h2{
    color: #07C180;
    font-size: 24px;
    text-align: center;
    padding: 40px 0px;
    text-transform: uppercase;
}
.submenu .submenu-container ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
}


.submenu .submenu-container ul li{
    padding: 30px 0px;
    border-bottom: 1px solid #3F4D7E;
}


.submenu .submenu-container ul li a{
    cursor: pointer;
    padding: 0px 25px;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
}
.wrapper-container {
    width: 100%;
    height: 100%;
    position: relative;
}
.wrapper-container .panel{
    width: 100%;
}

.wrapper-container .navbar{
    width: 100%;
    padding: 0px 25px;
    background: linear-gradient(180deg, #ffffff 0%, #ddecee 100%);
    background-blend-mode: normal;
}

.wrapper-container .navbar .container-search{
    font-family: Poppins;
    padding: 0px 0px;
}


.wrapper-container .navbar .option-navbar{
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    padding: 0px 10px;
    border-left: 1px solid #cbe0dd;
}
.wrapper-container .navbar .option-navbar .item{
    position: relative;
    top: 5px;
    text-transform: uppercase;
    color: #232c4a;
}

.wrapper-container .navbar .option-navbar .item .ant-dropdown-trigger .ant-space{

    color: #232c4a !important;
}

.wrapper-container .navbar .option-navbar .item .counter{
    margin-left: 10px;
    color: #07c180 !important;
    background-color: #fff;
    padding: 2px 8px;
    border-radius: 50%;
}

.wrapper-container .navbar .option-navbar .item .tracking{
    width: 20px;
    display: inline;
    margin-right: 5px;
}

.wrapper-container .navbar .option-navbar .item .img-profile{
    width: 25px;
    display: inline;
    margin-left: 10px;
}
.wrapper-container .navbar .search-navbar{
    padding-right: 10px;
}
.wrapper-container .navbar .search-navbar .ant-select-selector{
    border-radius: 8px !important;
}

.general-tabs{
    background: #fff;
    border-radius: 8px;
    overflow: auto;
}

.general-tabs .ant-tabs-tab {
    padding: 1.8em 4em;
}


.general-tabs .ant-tabs-nav{
    margin: 0px !important;
}

.general-tabs .ant-tabs-nav .ant-tabs-nav-wrap{
    justify-content: space-around !important;
}

.general-tabs .ant-table-pagination.ant-pagination {
    margin: 16px 15px;
}

.general-tabs .ant-table-thead > tr > th{
    padding: 15px 16px;
    font-size: 11px;
    color: #676767;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    background: #f5f9f9;
}


.general-tabs .ant-table-tbody > tr > td{
    text-align: center;
    font-size: 14px;
    color: #707070;
}


.general-tabs .ant-table-row:nth-child(even) {
	background: #f5f9f9;
}


.general-tabs .ant-tabs-nav-list .ant-tabs-tab-btn{
    text-transform: uppercase;
    color: #939393;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
}


.general-tabs .ant-tabs-nav-list .ant-tabs-tab-btn img{
    margin-right: 10px;
    width: 24px;
}

.general-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #939393;
}

.general-tabs .ant-tabs-nav-list .ant-tabs-tab-btn .alert{
    margin-left: 10px;
}

.alert{
    padding: 3px 6px;
    color: #fff;
    border-radius: 8px;
    background: #aaaaaa;
    font-size: 10px;
    background-blend-mode: normal;
    font-weight: 200;
}

.red-alert{
    background: #ff7c82;
}
.green-alert{
    background: #10b48c;
}

.blue-alert{
    background: #59adff;
}
.yellow-alert{
    background: #e8bb1f;
}


.general-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px !important;
    border-left: 1px solid #dedede;
}

.filter-buttons{
    border-radius: 8px !important;
    color: #adb9bb !important;
    font-family: Poppins !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    text-align: center !important;
    height: 22px !important;
    text-transform: uppercase;
    border: none !important
}

.wrapper-page{
    margin-top: 30px;
    padding: 0px 20px;
}

.wrapper-page .title-page{
  color: #232c4a;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}


.primary-tabs .ant-tabs-tab {
    padding: 1.5em 4em;
}


.primary-tabs .ant-tabs-nav-list .ant-tabs-tab-btn{
    text-transform: uppercase;
    color: #232c4a;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.primary-tabs .ant-table-thead > tr > th{
    padding: 15px 16px;
    font-size: 11px;
    color: #676767;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    background: #fff;
}


.primary-tabs .ant-table-tbody > tr > td{
    text-align: center;
    font-size: 14px;
    color: #707070;
}

.primary-tabs .ant-table-row:nth-child(even) {
	background: #f5f9f9;
}

.primary-tabs .ant-table {
    border-radius: 8px;
    overflow: hidden;
}

.side-modal{
    position: absolute !important;
    top: 0px;
    right: 0px !important;
    height: 100vh;
    overflow: auto;
}

.side-modal .ant-modal-content{
    height: 100vh;
    border-radius: 0px;
}

.side-modal 
.ant-modal-content 
.ant-modal-close{
    left: 0px;
}

.side-modal 
.ant-modal-content 
.ant-modal-header{
    text-align: center;
}
.side-modal 
.ant-modal-content 
.ant-modal-header
.ant-modal-title{
    text-align: center;
    color: #404040;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
}

.side-modal 
.ant-modal-content 
.ant-modal-close-x{
    color:#07c180;
}

.side-modal .section-modal{
    border-bottom: 1px solid #C9C9C9;
    /* padding: 20px 0px; */
}

.section-modal-active {
    text-align: right;
    justify-content: right;
    color:#10B48C;
    margin-left: 1rem;
    padding-right: 1rem;
    display:flex;
}

.section-modal-active p {
    padding-right: 1rem;
}

.section-modal-active img {
    margin-left: "10px";
    width: 20px;
    height: 20px;
}

.side-modal .user-data .user-image{
  width: 60px;
  border-radius: 50%;
}
.side-modal .section-modalModal{
    padding: 20px 5px;
  }
.side-modal .section-modal{
    padding: 20px;
  }
  .side-modal .title{
    color: #404040;
    text-transform: uppercase;
    font-size: 12px;
  }
  
  .side-modal .modal-header{
    padding-left: 10px;
  }
  
  .side-modal .modal-header h2{
    color: #232C4A;
    text-transform: capitalize;
    font-size: 20px;
  }
  
  .side-modal .datetime{
    color: #404040;
    font-weight: 600;
    position: relative;
    top: 7px;
  }
  
  
  .side-modal .user-data{
    display: flex;
    margin-top: 15px;
    align-items: flex-start;
  }
  
  .side-modal .user-data span{
    display: block;
    margin-left: 15px;
  }
  
  
  .side-modal 
  .user-data 
  .user-info 
  .user-name{
    color: #232C4A;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
  }
  
  
  .side-modal
  .user-data 
  .user-info 
  .user-address{
    color: #404040;
    font-size: 14px;
    text-transform: capitalize !important;
  }
  

  .btn-primary{
    border-radius: 26px;
    height: 46px;
    text-transform: uppercase;
    background-color: #10b48c !important;
    border: none !important;
  }

  .iconPerfil{
    width: 24px;
  }
    .user-image{
        width: 60px;
        height: 60px;
    }
  .numeroEncuesta{
      color: #10B48C;
      font-size: 24px;
  }
  .avatarUser{
    background-repeat:no-repeat;
    background-size:cover;
  }

  .iconMetodoPago{
      width: 30px;
  }

  .btn-cancel{
    border-radius: 26px;
    height: 46px;
    text-transform: uppercase;
    background-color: #dddddd !important;
    border: none !important;
  }

  .btn-borrador{
    border-radius: 26px;
    height: 46px;
    text-transform: uppercase;
    background-color: #CEB457 !important;
    border: none !important;
  }

  

  .btn-cancel > span{
    color: #000;
  }

  .side-modal .footer-modal{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  }
  
  .side-modal .footer-modal Button{
    margin: 10px;
  }

  .btn-deny{
    text-transform: uppercase;
    color: #fc4850 !important;
    background-color: #fff !important;
    border-radius: 24px;
    border: 1px solid #fc4850 !important;
  }



.primary-modal{
    box-shadow:  0px 0px 7px 5px #3D4E4E4E;
    border-radius: 8px;
}


.primary-modal .ant-modal-header{
    color: #232c4a;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.primary-modal .footer-buttons{
    margin-top: 20px;
    text-align: center;
}

.primary-modal .form-group{
    margin-bottom: 10px;
}

.primary-modal .form-group label{
    color: #404040;
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
}


.primary-modal .form-group .form-control{
    border-radius: 10px !important;
    border: 1px solid #dedede;
    width: 100%;
    padding: 5px 10px;
}

.btn-primary-radio{
    
  border-radius: 26px !important;
  background: #ffffff !important;
  height: 40px !important;
  box-shadow: 0px 2px 4px rgba(100,100,100,0.2);

  color: #10b48c !important;
  font-family: Poppins !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}


.btn-primary-radio img{
    margin-right: 10px;
}

.primary-card-list{
    list-style: none;
    max-height: 600px;
    overflow-y: auto;
}
.alto{
    height: 100vh;
}
.primary-tabs .ant-table-tbody > tr > td{
    font-size: 12.5px;
}

.primary-card-card{
    box-shadow:  0px 6px 7px 0px #409AB9D4;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: 7px;
    background: #ffffff;
    background-blend-mode: normal;
    box-shadow: 0px 6px 7px rgba(154,185,212,0.25);
    padding: 15px 20px;
    position: relative;
    cursor: pointer;
    margin: 25px 10px;
}

.primary-card-card .icon{
    margin-right: 25px;
}


.primary-card-card .shape-next{
    position: absolute;
    right: 10px;
}

.primary-card-info{
    line-height: 21px;
}


.primary-card-info h3{
  color: #232c4a;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

.primary-card-info span{
  color: #777777;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;

}


.primary-card-detail{
    padding: 30px 20px;
    max-height:  600px;
    min-height:  420px;
    border: 1px dashed #8fafb4;
    background: #fdffff;
    background-blend-mode: normal;
    overflow-y: auto;
}

.primary-card-detail 
.primary-card-section{
    margin-bottom: 25px;
}

.primary-card-detail 
.from 
h4{
    display: inline;
}

.primary-card-detail 
.from 
span{
    border-radius: 23px;
    border: 1px solid #e1e1e1;
    padding: 5px 10px;
    margin-left: 20px;
}


.primary-card-detail 
.primary-card-section
h4
{
  color: #404040;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}


.primary-card-detail 
.primary-card-section
p
{
  
    color: #232c4a;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
}

.ant-layout.ant-layout-has-sider{
    height: auto;
}
.ant-layout-sider{
    position: fixed !important;
}
.site-layout{
    margin-left: 80px;
}


@media (max-width: 701px) {
    .wrapper-container .navbar .search-navbar{
        display: none;
    }
}


@media (max-width: 664px) {
    .wrapper-container .navbar .track{
        line-height: 2;
        top: 3px;
    }
}

@media (max-width: 576px) {
    .wrapper-container .navbar .search-navbar{
        display: none;
    }
    .wrapper-container .navbar .track{
        display: none;
    }
    .wrapper-container .navbar .visited{
        display: none;
    }
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}

.table-name-cell-invited {
    text-align: left !important;
    padding-left: 6rem !important;
}

.table-name-cell-invited-no-padding {
    text-align: left !important;
}

.alert-modal-header p {
    color: #232C4A !important;
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  .alert-modal-text p {
    color: #404040 !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-left: 25px;
  }

  .ant-radio-checked .ant-radio-inner{
    border-color: #10B48C !important ;
  }
  
  .ant-radio-checked .ant-radio-inner:after{
    background-color: #10B48C;
  }
  
  .ant-radio:hover .ant-radio-inner {
    border-color: #10B48C !important ;
  }

  .select_style{
    background-color: white;
    border: "1px";
    border-radius: "10px";
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  }

