.linkEditar{
    border-radius: 12px;
    border: 1px solid #10B48C;
    cursor: pointer;
    margin: 0px auto;
    font-family: Poppins;
    font-size: 12px;
    height: 24px;
    padding-top: 2px;
}
.linkEditar img{
   width: 15px;
   height: 15px;
   margin-bottom: 3px;
   margin-right: 5px;
}
.scrollContModal{
    width: 100%;
    max-height: 580px;
    overflow-y: scroll;
}
.btn-primary-radio{
    margin-bottom: 20px;
}
.contBoton{
    display: flex;
    align-items: center;
    justify-content: right;
}
.contOpciones{
    display: flex;
}

.contOpciones li{
    display: block;
    width: 200px;
    text-align: center;
    color: #232C4A;
    font-family: Poppins;
}
.contOpciones div{
    color: #232C4A;
    font-family: Poppins;
    font-weight: 500;
    font-size: 13px;
    margin-top: 10px;
}
.contOpciones ul{
    padding-left: 0px;
    display: flex;
}
.iconOpcion{
    width: 70px;
    height: 70px;
    padding: 10px;
}