

.wrapper-login{
    position: absolute;
    background-image: url("/public/assets/images/background-login.png");
    height: 100%;
    width: 100%;
    background-position: center 0px;
    background-repeat: no-repeat;
    background-size: cover;
}

.wrapper-login .login-box{
    max-width: 500px;
    width: 100%;
    min-width: 300px;
    position: absolute;
    border-radius: 6px;
    background: linear-gradient(180deg, rgb(29, 11, 70, 0.5) 0%, rgb(36, 19, 90, 0.5) 50.08%, rgb(37, 29, 117, 0.5) 100%);
    background-blend-mode: normal;
    box-shadow: 0px 2px 4px 2px rgba(17,7,43,0.8);
    left: 0; 
    right: 0; 
    height: 550px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-left: auto; 
    margin-right: auto;
    padding: 20px 50px;
}

.wrapper-login .login-box .logo{
    min-width: 100px;
    width: initial;
}


.wrapper-login .login-box form{
    margin-top: 60px;
}

.wrapper-login .login-box .btn-login{
    width: 100%;
    border-radius: 26px;
    height: 46px;
    text-transform: uppercase;
    background-color: #10b48c;
}


.wrapper-login .material-icons{
    color: #10b48c;
}

#login-slogan{
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    margin-top: 50px;
}
#login-slogan span{
    color: #0ac598;
}

.wrapper-login .input-field label{
    color: #fff !important;
}

.wrapper-login .copyright{
    color: #fff;
    text-align: center;
    margin-top: 120px;
    font-size: 12px;
}

.wrapper-login .copyright span{    
    color: #0ac598;
}


.wrapper-login .ant-input-affix-wrapper{
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid #0ac598 !important;
    color: #0ac598;
}

.wrapper-login .ant-input-affix-wrapper .ant-input-prefix span{
    color: #0ac598;
}
.wrapper-login .ant-input-affix-wrapper input{
    background: transparent;
}

.wrapper-login .ant-input{
    color: #0ac598 !important;
    padding-left: 15px !important;
}

.wrapper-login .ant-input-suffix svg{
    color: #0ac598;
}

.wrapper-login .form-group{
    margin-bottom: 30px;
}

