.darkText {
        color: #232c4a !important;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 600;
}

.greenText {
        color: #10b48c !important;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 600;
}



.scale-up-ver-bottom {
	-webkit-animation: scale-up-ver-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  }
  @keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  }

  @-webkit-keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }
  @keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }