.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.btBuscar{
    margin-left: 10px;
    font-size: 12px !important;
    height: 30px !important;
    margin-bottom: 20px;
}
.clear{
    display: block;
    clear: both;
}
.contBusqueda{
    position: absolute;
    top: 27px;
    right: 0px;
}