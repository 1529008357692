.footer-wrapper{
    background-color: #0547AC;
}

.footer-content{
    color: #c3d2fe;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}

.description{
  color: #404040;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
}

.description-big{
  color: #404040;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.title_big{
  color: #232c4a;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
}

.title_small{
  color: #232c4a;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.green-description{
  color: #10b48c;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
}