.profile-user-data {
    background: #f5f7f8;
  background-blend-mode: normal;
  justify-content: center;
}

.profile-user-data-container {
    justify-content: center;
    padding-left: 6rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

}