.linkEditar{
    border-radius: 12px;
    border: 1px solid #10B48C;
    cursor: pointer;
    margin: 0px auto;
    font-family: Poppins;
    font-size: 12px;
    height: 24px;
    padding-top: 2px;
}
.linkEditar img{
   width: 15px;
   height: 15px;
   margin-bottom: 3px;
   margin-right: 5px;
}
.scrollContModal{
    width: 100%;
    max-height: 580px;
    overflow-y: scroll;
}
.btn-primary-radio{
    margin-bottom: 20px;
}
.espacioO{
    height: 50px;
}
.contBoton{
    display: flex;
    align-items: center;
    justify-content: right;
}
.noPaddingRight{
    padding-right: 0px;
    margin-top: 0px;
}
.noPaddingRight h2{
    margin-top: 30px;
}

.leyendaRight{
    height: 94vh;
    background-color: #fff;
    padding: 40px 40px;
}
.bienvenido{
    color: #232C4A;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
}
.topia{
    color:#10B48C;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
}
.textoLeyenda{
    color:#707070;
    font-family: Poppins;
    font-size: 13px;
}
.tituloLeyenda{
    color:#10B48C;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
}
.contBotonZona{
    text-align: left;
    display: none;
}
.desdeHasta{
    display: flex !important;
}
.desdeHasta label{
    padding-top: 7px;
    margin-right: 10px;
}

.desdeHasta input{
    width: 100px !important;
}
.espacio{
    height: 10px;
}
.edit {
    cursor: pointer;
}
.inputFormControl{
    height: 38px;
    padding: 0px 10px !important;
    font-size: 13px !important;
    border-radius: 10px !important;
    border: 1px solid #dedede;
    width: 100% !important;
}
.ant-table-tbody > tr > td {
    font-size: 12.5px;
}
.iconoPerfil{
    margin-left: 10px;
    width: 18px;
    float: left;
}
.emails{
    margin-left: 10px !important;
    display: block !important;
    font-size: 10px;
    text-decoration: underline;
}
.contEmails{
    float: left;
}
.contEmail{
    display: -webkit-box;
}
.detalleP{
    font-size: 11px;
}
.linea{
    border: 1px solid #dedede;
    margin-bottom: 10px;
    padding: 9px;
}
.btEditar{
    height: 29px !important;
    padding: 3px 23px;
    margin-top: 10px;
    margin-left: 10px;
    border-color: #10b48c !important;
}
.btEditar span{
    margin-left: 0px !important;
}
.aprobar{
    width: 16px;
    margin-bottom: 4px;
    margin-right: 5px !important;
}
.btCenter{
    margin-top: 0px;
    margin-bottom: 50px;
    width: 200px;
}